<template>
  <a href="#" class="logo">
    <img src="@/assets/images/logo/app-logo.svg" alt="ETQueue" />
  </a>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.logo,
.logo img
  display: block

.logo img
  @media screen and (max-width: 600px)
    max-width: 110px

  @media screen and (max-width: 350px)
    max-width: 100px
</style>
