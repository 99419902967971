<template>
  <div id="faq" class="faq">
    <div class="container--main-page">
      <div class="faq__content">
        <Heading level="3" class="faq__title"> F&Q </Heading>
        <div class="faq__list">
          <div
            v-for="(item, index) in faqContent"
            :key="item.title"
            class="faq__item-container"
          >
            <div
              class="faq__item"
              :class="{ opened: activeFaq === index }"
              @click.stop="toggleFaqItem(index)"
            >
              <div class="faq__item-title">{{ item.title }}</div>
              <div
                class="faq__cross"
                :class="{
                  opened: activeFaq === index,
                }"
              ></div>
            </div>
            <div v-if="activeFaq === index" class="faq__text">
              <i18n :path="item.text">
                <br />
              </i18n>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Heading from '@/elements/Heading/Heading.vue';

export default {
  components: {
    Heading,
  },
  data() {
    return {
      faqContent: [
        {
          title: this.$t('main_page.time_queue_title'),
          text: 'main_page.time_queue',
        },
        {
          title: this.$t('main_page.our_privilagy_title'),
          text: 'main_page.our_privilagy',
        },
        {
          title: this.$t('main_page.my_profit_title'),
          text: 'main_page.my_profit',
        },
      ],
      activeFaq: null,
    };
  },
  computed: {},
  methods: {
    toggleFaqItem(index) {
      if (this.activeFaq === index) {
        this.activeFaq = null;
        return;
      }
      this.activeFaq = index;
    },
  },
};
</script>
<style lang="sass">
.faq
  padding: 120px 0
  @media screen and (max-width: 767px)
    padding: 80px 0

.faq__content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.faq__title
  font-weight: 600
  margin-top: 0

  @media screen and (max-width: 600px)
    font-size: 32px
    line-height: 36px
  @media screen and (max-width: 350px)
    font-size: 28px
    line-height: 28px

.faq__list
  width: 100%

.faq__item-container
  border-bottom: 1px solid var(--primary-color-light-grey-2)

.faq__item
  display: flex
  justify-content: space-between
  position: relative
  padding: 32px 0
  cursor: pointer
  &:after
    position: absolute
    content: ''
    top: 50%
    right: -14px
    transform: translateY(-50%)
    width: 48px
    height: 48px
    border-radius: 50%
    border: 1px solid rgba(48, 83, 240, 0.5)
    opacity: 0
    @media screen and (max-width: 767px)
      display: none
  @media screen and (max-width: 600px)
    padding: 24px 0

.faq__item:hover:after
  opacity: 1

.faq__item.opened
  border: none
  padding-bottom: 24px

.faq__item.opened:after
  transform: translateY(calc(-50% + 4px))

.faq__item-title
  font-size: 24px
  font-weight: 600
  max-width: 95%
  @media screen and (max-width: 600px)
    font-size: 18px
    line-height: 21px

  @media screen and (max-width: 350px)
    font-size: 16px
    line-height: 19px

.faq__text
  padding-right: 42px
  font-size: 20px
  line-height: 30px

  @media screen and (max-width: 600px)
    font-size: 16px
    line-height: 22px
  @media screen and (max-width: 350px)
    font-size: 14px
    line-height: 19px

.faq__item.opened + .faq__text
  padding-bottom: 32px

  @media screen and (max-width: 600px)
    padding-bottom: 24px

.faq__cross
  position: relative
.faq__cross:after,
.faq__cross:before
  position: absolute
  content: ''
  width: 2px
  height: 18px
  top: 5px
  right: 9px
  background-color: var(--btn-color-blue)
  @media screen and (max-width: 600px)
    top: 2px
    right: 6px
    height: 14px

.faq__cross:before
  transform: rotate(90deg)

.faq__cross.opened:after
  display: none
</style>
