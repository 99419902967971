<template>
  <div class="mainpage">
    <div class="booking">
      <div class="booking__inner">
        <div class="container--main-page">
          <div class="booking__content">
            <AppLogo class="booking__logo" />
            <Heading level="3" class="booking__text">
              {{ $t('main_page.booking_title') }}
            </Heading>
            <ButtonBase
              color="blue"
              size="max"
              class="booking__btn"
              @click.native="goToCreateTicket"
            >
              {{ $t('main_page.to_book') }}
            </ButtonBase>
            <div class="booking__row">
              <a href="#how-work"> {{ $t('main_page.how_sing_up') }} </a>
              <a href="#usefull-info">{{ $t('main_page.usefull_info') }}</a>
            </div>
          </div>
        </div>
        <div class="booking__img-box">
          <img
            class="booking__img"
            src="@/assets/images/main-good-man.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <div id="how-work" class="how-work">
      <div class="container--main-page">
        <div class="how-work__content">
          <div class="how-work__small-title small-title">
            {{ $t('main_page.how_it_work') }}
          </div>
          <Heading level="3" class="how-work__text">
            {{ $t('main_page.four_steps') }}
          </Heading>
          <div class="how-work__row items">
            <div class="items__row">
              <div class="item-wrapper">
                <div class="item">
                  <img src="/images/how-work-user.svg" class="item__icon" />
                </div>
                <div class="item__subtitle item__subtitle--user">
                  {{ $t('main_page.registration') }}
                </div>
              </div>
              <img
                src="/images/how-work-length-left.svg"
                class="item__length--left"
              />
              <div class="item-wrapper-barrier">
                <div class="item">
                  <img src="/images/how-work-barrier.svg" class="item__icon" />
                </div>
                <div class="item__subtitle">
                  {{ $t('main_page.choose_point') }}
                </div>
              </div>
              <div class="item-wrapper-date--small">
                <div class="item">
                  <img src="/images/how-work-date.svg" class="item__icon" />
                </div>
                <div class="item__subtitle">
                  <i18n path="main_page.choose_date">
                    <br />
                  </i18n>
                </div>
              </div>
              <img
                src="/images/how-work-length-center.svg"
                class="item__length--center"
              />
            </div>
            <div class="how-work__curves">
              <img
                src="/images/how-work-length-horizont.svg"
                class="item__length--top"
              />
              <img
                src="/images/how-work-length-angle.svg"
                class="item__length--angle"
              />
              <img
                src="/images/how-work-length-horizont.svg"
                class="item__length--bottom"
              />
            </div>
            <div class="items__row">
              <div class="item-wrapper-date">
                <div class="item">
                  <img src="/images/how-work-date.svg" class="item__icon" />
                </div>
                <div class="item__subtitle item__subtitle--date">
                  <i18n path="main_page.choose_date">
                    <br />
                  </i18n>
                </div>
              </div>
              <div class="item-wrapper-barrier--small">
                <div class="item">
                  <img src="/images/how-work-barrier.svg" class="item__icon" />
                </div>
                <div class="item__subtitle">
                  {{ $t('main_page.choose_point') }}
                </div>
              </div>
              <img
                src="/images/how-work-length-right.svg"
                class="item__length--right"
              />
              <div class="item-wrapper card">
                <div class="item">
                  <img src="/images/how-work-card.svg" class="item__icon" />
                </div>
                <div class="item__subtitle">
                  {{ $t('main_page.buy_ticket') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="how-get" class="how-get">
      <div class="how-get__inner">
        <div class="how-get__img-box">
          <picture>
            <source
              srcset="@/assets/images/truck-sm.png"
              media="(max-width: 650px)"
            />
            <source
              srcset="@/assets/images/truck-m.png"
              media="(max-width: 1199px)"
            />
            <img
              class="how-get__img"
              srcset="@/assets/images/truck.png"
              alt=""
            />
          </picture>
        </div>
        <div class="container--main-page">
          <div class="how-get__content">
            <div class="small-title">
              {{ $t('main_page.how_book') }}
            </div>
            <Heading level="3" class="how-get__text">
              {{ $t('main_page.instruction') }}
            </Heading>
            <ul class="how-get__list">
              <li>
                {{ $t('main_page.push_btn') }}
                <span class="fw-700"> {{ $t('main_page.to_book_text') }} </span>
              </li>
              <li>
                {{ $t('main_page.second_step') }}
              </li>
              <li>
                {{ $t('main_page.third_step') }}
              </li>
              <li>
                {{ $t('main_page.fourth_step') }}
              </li>
              <li>
                {{ $t('main_page.fifth_step') }}
              </li>
              <li>
                {{ $t('main_page.sixth_step') }}
              </li>
              <li>
                {{ $t('main_page.seventh_step') }}
              </li>
            </ul>
            <ButtonBase
              color="blue"
              size="max"
              class="how-get__btn"
              @click.native="goToCreateTicket"
            >
              {{ $t('main_page.to_book') }}
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>

    <div class="note">
      <div class="note__inner">
        <div class="container--main-page">
          <div class="note__content">
            <div class="note__text">
              {{ $t('main_page.promo_text') }}
              <span>
                {{ $t('main_page.promo_service_name') }}
              </span>
            </div>
            <div class="note__subtext">
              {{ $t('main_page.get_participation') }}
            </div>
            <div class="note__btn">
              <ButtonBase color="blue" size="m">
                {{ $t('main_page.aproove') }}
              </ButtonBase>
            </div>
          </div>
        </div>
        <div class="note__img-box">
          <img class="note__img" src="@/assets/images/adv-car.png" alt="" />
        </div>
      </div>
    </div>

    <div id="usefull-info" class="usefull-info">
      <div class="usefull-info__inner">
        <div class="usefull-info__img-box">
          <img
            class="usefull-info__img"
            src="@/assets/images/man-in-truck.png"
            alt=""
          />
        </div>
        <div class="container--main-page">
          <div class="usefull-info__content">
            <div class="small-title">{{ $t('main_page.usefull_info') }}</div>
            <Heading level="3" class="how-get__text">
              {{ $t('main_page.info_about_booking') }}
            </Heading>
            <ul class="usefull-info__list">
              <li>
                {{ $t('main_page.book_step_part_1') }}
                <span class="bold"> {{ $t('main_page.book_step_bold') }}</span
                >,
                {{ $t('main_page.book_step_part_2') }}
              </li>
              <li>
                <i18n path="main_page.second_book_step">
                  <br />
                </i18n>
                <span class="bold">
                  {{ $t('main_page.second_book_step_bold') }}</span
                >.
              </li>
              <li>
                <i18n path="main_page.third_book_step">
                  <br />
                </i18n>
                <span class="bold">
                  {{ $t('main_page.third_book_step_bold') }}</span
                >.
              </li>
              <li>
                {{ $t('main_page.fourth_book_step_part_1') }}
                <span class="bold">
                  {{ $t('main_page.fourth_book_step_bold') }}
                </span>
                {{ $t('main_page.fourth_book_step_part_2') }}
              </li>
              <li>
                {{ $t('main_page.fiveth_book_step') }}
              </li>
              <li>
                {{ $t('main_page.sixth_book_step') }}
                <a href="#" class="usefull-info__rules">
                  {{ $t('main_page.sixth_book_step_url') }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="polis">
      <div class="polis__inner">
        <div class="container--main-page">
          <div class="polis__content">
            <Heading level="3" class="polis__title">
              <i18n path="main_page.order_polis">
                <br />
              </i18n>
            </Heading>
            <div class="polis__subtext">
              {{ $t('main_page.order_polis_today') }}
            </div>
            <ButtonBase color="blue" size="m" class="polis__btn">
              {{ $t('main_page.polis_buy') }}
            </ButtonBase>
          </div>
        </div>
        <div class="polis__img-box">
          <img class="polis__img" src="@/assets/images/polis-car.png" alt="" />
        </div>
      </div>
    </div>

    <div class="calling">
      <div class="container--main-page">
        <div class="calling__content">
          <div class="small-title">
            {{ $t('main_page.questions') }}
          </div>
          <Heading level="3" class="calling__title">
            <i18n path="main_page.more_details">
              <br />
            </i18n>
          </Heading>
          <a href="tel:+38063347442" class="calling__link">
            <ButtonBase color="blue-transparent" size="m" class="calling__btn">
              {{ $t('main_page.to_call') }}
            </ButtonBase>
          </a>
        </div>
      </div>
    </div>
    <MainPageFAQ />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AppLogo from '@/components/Logo/AppLogo.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Heading from '@/elements/Heading/Heading.vue';
import MainPageFAQ from '@/components/MainPage/MainPageFAQ.vue';

export default {
  components: {
    AppLogo,
    ButtonBase,
    Heading,
    MainPageFAQ,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isAuthUser']),
  },
  mounted() {
    const url = new URL(window.location.href);
    const isHasAnchor = !!url.hash;

    if (isHasAnchor) {
      const targetElementId = url.hash.slice(1);
      const offsetTop = document.getElementById(targetElementId).offsetTop;

      window.scroll({
        top: offsetTop,
      });
    }
  },
  methods: {
    goToCreateTicket() {
      if (this.isAuthUser) {
        this.$router.push({ name: 'NewTicketCustomsPost' });
      } else {
        this.$router.push({ name: 'Auth' });
      }
    },
  },
};
</script>
<style lang="sass">
.mainpage .primary-btn--blue
  box-shadow: 0px 4px 8px rgba(62, 85, 236, 0.25)

.booking
  position: relative
  box-shadow: 0px 32px 80px rgb(38 55 76 / 5%)
  height: calc(100vh - 81px)

  @media screen and (max-width: 1199px)
    height: calc(100vh - 79px)

  @media screen and (max-width: 991px)
    min-height: 550px

  @media screen and (max-width: 600px)
    height: calc(100vh - 61px)

  @media screen and (max-width: 350px)
    min-height: 450px

.booking__inner
  position: relative
  display: flex
  align-items: center
  height: 100%

.booking__content
  display: flex
  flex-direction: column
  width: 50%
  padding-right: 115px

  @media screen and (max-width: 1199px)
    padding-right: 40px

  @media screen and (max-width: 1020px)
    width: 100%
    align-items: center
    padding-right: 0

.booking__logo.logo img
  height: 50px
  max-width: 100%

  @media screen and (max-width: 600px)
    height: 47px

  @media screen and (max-width: 350px)
    height: 40px

.booking__column
  @media screen and (max-width: 1020px)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100vh

.booking__text
  @media screen and (max-width: 1020px)
    text-align: center

.booking__text.title--3
  font-weight: 600
  margin-top: 26px
  margin-bottom: 48px

  @media screen and (max-width: 1199px)
    font-size: 48px
    line-height: 56px

  @media screen and (max-width: 600px)
    font-size: 32px
    line-height: 40px
    margin-bottom: 42px

  @media screen and (max-width: 350px)
    font-size: 24px
    line-height: 32px
    margin-top: 20px

.booking__btn.primary-btn--max
  margin-bottom: 40px
  height: 56px

  @media screen and (max-width: 1020px)
    max-width: 460px
  @media screen and (max-width: 600px)
    height: 52px
    font-size: 16px
    line-height: 19px

  @media screen and (max-width: 350px)
    height: 44px
    font-size: 14px

.booking__row
  display: flex
  justify-content: space-between

  @media screen and (max-width: 1020px)
    width: 100%
    max-width: 460px

.booking__row a
  font-size: 18px
  color: var(--btn-color-blue)
  border-bottom: 1px solid transparent

  &:hover
    border-bottom: 1px solid var(--btn-color-blue)

  @media screen and (max-width: 1230px)
    font-size: 16px
  @media screen and (max-width: 350px)
    font-size: 14px

.booking__img-box
  position: absolute
  top: 0
  right: 0
  width: 50%
  height: 100%

  @media screen and (max-width: 1020px)
    display: none

.booking__img
  height: 100%
  object-fit: cover
  object-position: center
  display: block

.small-title
  font-size: 24px
  letter-spacing: 4px
  font-weight: 600
  color: var(--primary-color-dark-blue)
  opacity: 0.6

  @media screen and (max-width: 600px)
    font-size: 16px
  @media screen and (max-width: 350px)
    font-size: 14px

.how-work
  background-color: var(--primary-color-light-grey-1)
  text-align: center
  padding: 200px 0 250px 0

  @media screen and (max-width: 1199px)
    padding: 150px 0 200px 0

  @media screen and (max-width: 991px)
    padding: 45px 0 200px 0

  @media screen and (max-width: 767px)
    padding: 60px 0 110px 0

.how-work__content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  @media screen and (max-width: 1199px)
    width: 100%

.how-work__text.title--3
  font-weight: 600
  line-height: 56px
  text-align: center
  max-width: 600px
  margin-top: 16px
  margin-bottom: 64px
  @media screen and (max-width: 1199px)
    line-height: 48px
    margin-bottom: 52px

  @media screen and (max-width: 600px)
    font-size: 24px
    line-height: 30px
    margin-top: 20px
    margin-bottom: 40px

  @media screen and (max-width: 350px)
    font-size: 20px
    line-height: 26px
    margin-top: 16px
    margin-bottom: 32px

.how-work__row
  display: flex
  @media screen and (max-width: 1199px)
    justify-content: center
    padding: 0 32px
  @media screen and (max-width: 650px)
    max-width: 360px
  @media screen and (max-width: 359px)
    max-width: 320px

.item-wrapper,
.item-wrapper-barrier,
.item-wrapper-date--small,
.item-wrapper-date,
.item-wrapper-barrier--small
  position: relative
  display: flex
  align-items: center
  flex-direction: column

.item-wrapper-barrier,
.item-wrapper-date
  @media screen and (max-width: 1199px)
    display: none

.item-wrapper-date--small,
.item-wrapper-barrier--small
  display: none
  @media screen and (max-width: 1199px)
    position: relative
    display: flex

.item
  display: flex
  align-items: center
  justify-content: center
  width: 120px
  height: 120px
  box-shadow: 0px 12px 40px rgba(38, 55, 76, 0.08)
  border-radius: var(--primary-border-radius)
  margin-bottom: 16px

  @media screen and (max-width: 650px)
    width: 80px
    height: 80px

.items__row
  display: flex
  @media screen and (max-width: 1199px)
    flex-direction: column
    align-items: center
    justify-content: space-between

.item__subtitle
  position: absolute
  bottom: -54px
  min-width: 210px
  font-size: 24px
  font-weight: 600

  @media screen and (max-width: 600px)
    font-size: 14px
    line-height: 17px
    bottom: -24px
    min-width: 122px

.item__subtitle--user
  bottom: -28px

.item__subtitle--user
  @media screen and (max-width: 650px)
    bottom: -14px

.item__icon
  width: 56px
  @media screen and (max-width: 650px)
    width: 40px

.item__length--left,
.item__length--center,
.item__length--right
  position: relative
  height: 32px
  top: 25px

  @media screen and (max-width: 1199px)
    display: none

.how-work__curves
  display: none
  flex-direction: column
  justify-content: space-between
  min-height: 420px

  @media screen and (max-width: 1199px)
    display: flex
  @media screen and (max-width: 650px)
    min-height: 290px
    align-items: center
  @media screen and (max-width: 650px)
    min-height: 240px

.item__length--center
  top: 65px

.item__length--top,
.item__length--bottom
  position: relative
  top: 60px
  left: 30px
  width: 190px

  @media screen and (max-width: 650px)
    left: 0
    top: 40px
    max-width: calc(100% - 30px)

.item__length--bottom
  top: -60px
  @media screen and (max-width: 650px)
    top: -40px

.item__length--angle
  @media screen and (max-width: 650px)
    width: 70%

.how-get
  background-color: var(--primary-color-white)
  padding: 160px 0
  @media screen and (max-width: 650px)
    padding: 64px 0

.how-get__inner
  display: flex
  align-items: center
  height: 100%
  position: relative

  @media screen and (max-width: 1199px)
    flex-direction: column

.how-get__img-box
  position: absolute
  top: 0
  left: 0
  width: 50%
  height: 100%
  display: flex
  align-items: center

  @media screen and (max-width: 1199px)
    position: static
    margin-bottom: 60px

  @media screen and (max-width: 600px)
    margin-bottom: 50px

  @media screen and (max-width: 350px)
    margin-bottom: 40px

.how-get__img
  object-fit: cover

.how-get__content
  display: flex
  flex-direction: column
  width: 50%
  margin-left: auto

  @media screen and (max-width: 1199px)
    align-items: center
    width: 100%

.how-get__text.title--3
  line-height: 56px
  font-weight: 600
  position: relative
  margin-top: 16px
  margin-bottom: 48px
  @media screen and (max-width: 1199px)
    font-size: 48px
    max-width: 650px
    text-align: center
    margin-top: 16px

  @media screen and (max-width: 600px)
    font-size: 24px
    line-height: 30px
    margin-bottom: 40px

  @media screen and (max-width: 350px)
    font-size: 20px
    line-height: 26px
    max-width: 220px

.how-get__text:before
  position: absolute
  content: ''
  height: 4px
  width: 120px
  bottom: -24px
  background-color: var(--btn-color-blue)

  @media screen and (max-width: 1199px)
    left: 50%
    bottom: -22px
    transform: translateX(-50%)

li
  font-weight: 400

  span.bold
    font-weight: 600

.how-get__list
  display: flex
  flex-direction: column
  gap: 24px
  font-size: 24px
  line-height: 32px
  font-weight: 600

  @media screen and (max-width: 600px)
    font-size: 16px
    line-height: 22px
    gap: 16px

  @media screen and (max-width: 350px)
    font-size: 14px
    line-height: 18px

.how-get__btn
  margin-top: 40px

.how-get__btn.primary-btn--max
  height: 56px
  @media screen and (max-width: 1199px)
    align-self: center
    max-width: 460px

  @media screen and (max-width: 600px)
    height: 52px
    font-size: 16px

  @media screen and (max-width: 350px)
    height: 44px
    font-size: 14px

.note
  background-color: #000
  position: relative

.note__img-box
  position: absolute
  bottom: 0px
  right: -55px
  z-index: 0

  @media screen and (min-width: 400px)
    right: 0
  @media screen and (min-width: 650px)
    right: -125px
  @media screen and (min-width: 950px)
    right: 0

.note__img
  height: 90px

  @media screen and (min-width: 450px)
    height: 110px
  @media screen and (min-width: 650px)
    height: 210px
  @media screen and (min-width: 950px)
    height: 250px

.note__text
  position: relative
  font-size: 48px
  font-weight: 600
  line-height: 56px
  color: var(--primary-color-white)
  padding-top: 54px
  z-index: 1
  @media screen and (max-width: 600px)
    font-size: 24px
    line-height: 28px
    padding-top: 20px
  @media screen and (max-width: 350px)
    font-size: 20px
    line-height: 23px
    max-width: 210px
  @media screen and (min-width: 651px)
    max-width: 500px

  span
    color: var(--btn-color-blue)

.note__subtext
  color: var(--primary-color-white)
  font-size: 24px

.polis__subtext
  color: var(--primary-color-dark-blue)
  font-size: 18px

.note__subtext,
.polis__subtext
  position: relative
  line-height: 32px
  font-weight: normal
  margin: 12px 0 24px
  z-index: 1

  @media screen and (max-width: 600px)
    font-size: 12px
    line-height: 14px
    margin: 8px 0 16px
  @media screen and (max-width: 350px)
    font-size: 10px
    line-height: 13px
    margin: 5px 0 10px

.note__btn
  padding-bottom: 16px
  @media screen and (min-width: 350px)
    padding-bottom: 20px
  @media screen and (min-width: 650px)
    padding-bottom: 38px

.note__btn .primary-btn--medium
  position: relative
  height: 48px
  z-index: 1
  @media screen and (max-width: 600px)
    font-size: 12px
    width: 128px
    height: 32px
    border-radius: 2px
  @media screen and (max-width: 350px)
    font-size: 7.5px
    width: 100px
    height: 20px

.usefull-info
  padding: 145px 0 180px 0

  @media screen and (max-width: 1199px)
    padding: 160px 0

  @media screen and (max-width: 767px)
    padding: 88px 0

.usefull-info__inner
  position: relative

.usefull-info__content
  display: flex
  flex-direction: column
  width: 55%
  padding-right: 132px
  @media screen and (max-width: 1199px)
    width: 100%
    align-items: center
    padding-right: 0

.usefull-info__img-box
  position: absolute
  top: 0
  right: 0
  width: 45%
  height: 100%

  @media screen and (max-width: 1199px)
    position: static
    width: 100%
    margin-bottom: 64px
    padding: 0 64px

  @media screen and (max-width: 767px)
    margin-bottom: 44px
    padding: 0

.usefull-info__img
  height: 100%
  object-fit: cover
  display: block
  @media screen and (max-width: 1199px)
    max-height: 400px
    width: 100%
  @media screen and (max-width: 767px)
    max-height: 310px

.usefull-info__list
  display: flex
  flex-direction: column
  gap: 24px
  line-height: 30px
  font-size: 24px
  font-weight: 600
  @media screen and (max-width: 600px)
    font-size: 16px
    line-height: 22px
    gap: 16px

  @media screen and (max-width: 350px)
    font-size: 14px
    line-height: 18px

.usefull-info__rules
  color: var(--btn-color-blue)
  border-bottom: 1px solid transparent
  cursor: pointer

  &:hover
    border-bottom: 1px solid var(--btn-color-blue)

.polis
  background-color: var(--primary-color-white)
  border-bottom: 1px solid var(--primary-color-light-grey-2)

  @media screen and (max-width: 991px)
    border-bottom: none

.polis__inner
  position: relative

.polis__content
  position: relative
  display: flex
  flex-direction: column
  width: 95%
  white-space: nowrap
  padding: 42px 0 54px
  z-index: 2
  margin-right: -10%
  background: linear-gradient(89.98deg, var(--primary-color-white) 87.98%, #FFFFFF 83.85%, rgba(255, 255, 255, 0) 99.98%)
  @media screen and (max-width: 1600px)
    width: 85%
  @media screen and (max-width: 1400px)
    width: 70%
  @media screen and (max-width: 991px)
    width: 80%
  @media screen and (max-width: 650px)
    padding: 17px 0 22px 0

.polis__img-box
  position: absolute
  height: 100%
  bottom: 0
  right: 0
  z-index: 1
  @media screen and (max-width: 1199px)
    right: -122px
  @media screen and (max-width: 600px)
    height: 100%
    right: -55px

.polis__img
  object-fit: cover
  display: block
  height: inherit

  @media screen and (max-width: 1199px)
    height: 320px
  @media screen and (max-width: 600px)
    height: 100%

.polis__title.title--3
  line-height: 56px
  max-width: 520px
  font-size: 48px
  font-weight: 600
  margin: 0

  @media screen and (max-width: 600px)
    font-size: 24px
    line-height: 28px
  @media screen and (max-width: 350px)
    font-size: 20px
    line-height: 23px
    max-width: 210px

.polis__btn.primary-btn--medium
  height: 48px
  @media screen and (max-width: 600px)
    font-size: 12px
    width: 128px
    height: 32px
    border-radius: 2px
  @media screen and (max-width: 350px)
    font-size: 7.5px
    width: 100px
    height: 20px

.calling
  align-items: center
  padding: 120px 0
  background-color: var(--primary-color-white)
  box-shadow: var(--primary-border-shadow-1)
  @media screen and (max-width: 1199px)
    padding: 88px 0
  @media screen and (max-width: 600px)
    padding: 64px 0

.calling__content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.calling__title.title--3
  line-height: 56px
  font-weight: 600
  text-align: center
  margin-top: 20px
  @media screen and (max-width: 1199px)
    line-height: 48px
  @media screen and (max-width: 600px)
    font-size: 24px
    line-height: 30px

  @media screen and (max-width: 350px)
    font-size: 20px
    line-height: 26px
    margin-top: 16px

.calling__link
  display: block
  @media screen and (max-width: 600px)
    width: 100%

.calling__btn.primary-btn
  width: 325px
  height: 56px
  position: relative
  &:after
    position: absolute
    content: ''
    width: 100%
    height: 100%
    left: 0
    top: 0
    background-color: transparent
    border: 1px solid var(--btn-color-blue)
    opacity: 0
    transition: var(--transition-speed)
  @media screen and (max-width: 600px)
    width: inherit
    height: 52px
    font-size: 16px
  @media screen and (max-width: 350px)
    height: 44px
    font-size: 14px

.calling__btn.primary-btn:hover:after
  opacity: 1
</style>
